import React from 'react';

import Layout from 'src/components/layout/layout';
import { getClientProps } from 'src/components/players/client-props';
import Players from 'src/components/players/players';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const PlayersPage = () => (
  <Layout>
    <SEO title="Players" />
    <PrivateRoute>
      <Players {...getClientProps()} />
    </PrivateRoute>
  </Layout>
);

export default PlayersPage;
