// extracted by mini-css-extract-plugin
export var age = "players-module--age--728b2";
export var bodyBase = "players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodyLarge = "players-module--body-large--7ddef players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodyLargeBold = "players-module--body-large-bold--a26e9 players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodyRegular = "players-module--body-regular--e50a7 players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodyRegularBold = "players-module--body-regular-bold--8f227 players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodySmall = "players-module--body-small--41f58 players-module--body-base--ad558 players-module--site-font--d28d0";
export var bodySmallBold = "players-module--body-small-bold--e3f5d players-module--body-base--ad558 players-module--site-font--d28d0";
export var borderTop = "players-module--border-top--2482d";
export var breakWordContainer = "players-module--break-word-container--f34d8";
export var buttonIconBase = "players-module--button-icon-base--9f788";
export var clickLink = "players-module--click-link--4b730";
export var dropdownBase = "players-module--dropdown-base--3d277";
export var dropdownSelectBase = "players-module--dropdown-select-base--c7a3d players-module--text-input--0b406";
export var flexCol = "players-module--flex-col--b479d";
export var formErrorMessage = "players-module--form-error-message--0878e";
export var gender = "players-module--gender--67f29";
export var h3 = "players-module--h3--4d2a5";
export var h4 = "players-module--h4--05606";
export var hoverLink = "players-module--hover-link--53781";
export var hoverRow = "players-module--hover-row--5780d";
export var membershipContainer = "players-module--membership-container--3f86c players-module--flex-col--b479d players-module--primary-border--2ba6c";
export var membershipHeader = "players-module--membership-header--0afd2";
export var membershipHeading = "players-module--membership-heading--80d9e";
export var membershipLabel = "players-module--membership-label--81c3c";
export var moreFiltersBorderClass = "players-module--more-filters-border-class--a3e8a";
export var pageBg = "players-module--page-bg--e060a";
export var personId = "players-module--personId--e672d";
export var pointer = "players-module--pointer--ed6e9";
export var primaryBorder = "players-module--primary-border--2ba6c";
export var shadowBoxLight = "players-module--shadow-box-light--7d37b";
export var siteFont = "players-module--site-font--d28d0";
export var slideDownAndFade = "players-module--slideDownAndFade--c5e65";
export var slideLeftAndFade = "players-module--slideLeftAndFade--1a8ac";
export var slideRightAndFade = "players-module--slideRightAndFade--175fd";
export var slideUpAndFade = "players-module--slideUpAndFade--b25f9";
export var statusDecoration = "players-module--status-decoration--917ac";
export var stdName = "players-module--stdName--6f0e9";
export var tableCell = "players-module--tableCell--48873";
export var tennisId = "players-module--tennisId--ac007";
export var textInput = "players-module--text-input--0b406";
export var textInverted = "players-module--text-inverted--db764";
export var textMediumDark = "players-module--text-medium-dark--c012b";
export var tooltipFont = "players-module--tooltipFont--5db34";
export var unstyledButton = "players-module--unstyled-button--d3e85";
export var wtnRating = "players-module--wtnRating--bf5f8";