import graphql from 'graphql-tag';

export const SEARCH_PLAYERS = graphql`
  query SearchPlayers($limit: Int!, $skip: Int!, $filter: PersonFilterOptionsInput, $sort: SortOrder) {
    searchPeople(pageArgs: { limit: $limit, skip: $skip }, filter: $filter, sort: $sort) {
      results {
        id: personId
        standardFamilyName
        standardGivenName
        age
        nationalityCode
        birthDate
        sex
        tennisId
        worldTennisNumbers {
          type
          tennisNumber
          confidence
        }
        updatedAt
      }
      total
    }
  }
`;
