import { useMemo } from 'react';

import cx from 'classnames';
import { navigate } from 'gatsby';
import { print } from 'graphql';
import { meshGatewayClient } from 'src/apollo/client';
import { AGE_RANGE_DEFAULT, isDefaultAgeRange } from 'src/components/filter-bar/constants';
import {
  isRangeOutOfBounds,
  useAgeFilter,
  useGenderFilter,
  useNationalityFilter,
  useSearchFilter,
  useSectionAndDistrictFilters,
} from 'src/components/filter-bar/utils';
import { CSVTransform, CSVValueTransform } from 'src/components/generate-report/generate-report';
import Players from 'src/components/players/players';
import * as queriesUSTA from 'src/components/players/players-queries';
import { transformSortDirection } from 'src/components/table-controls/table-controls';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { SearchPlayers, SearchPlayers_searchPeople_results } from 'src/graphql-types/default/SearchPlayers';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { CommonSearchOperatorsFieldsEnum, SexEnum } from 'src/graphql-types/ita/globalITATypes';
import { SearchPlayers_players_results, SearchPlayers as SearchPlayersITA } from 'src/graphql-types/ita/SearchPlayers';
import { GroupRole, GroupType } from 'src/graphql-types/ita/shared';
import { SearchPeople_searchPeople_results, SearchPeople as SearchPlayersUSTA } from 'src/graphql-types/SearchPeople';
import { Spacing } from 'src/hooks/spacing';
import * as queriesDEFAULT from 'src/queries/default/index';
import * as queriesITA from 'src/queries/ITA/index';
import { ClientPropsConfig, PropsFrom } from 'src/types';
import { useCountries } from 'src/utils/helper/countries';
import { useDateFormat } from 'src/utils/helper/dates';
import filterOperatorHelper from 'src/utils/helper/filters';
import { personGenderTranslationMap, usePersonGender } from 'src/utils/helper/players';
import { retrieveColumnPreference } from 'src/utils/storage/local-storage';
import { getWtnValues } from 'src/utils/wtn';

import * as styles from './players.module.less';

const filterSpacing: Spacing = {
  base: 6,
  margins: {
    xs: ['bottom', 'right'],
  },
};

const accessoriesBarSpacing: Spacing = {
  base: 5,
  margins: {
    xs: 'top',
    md: 'bottom',
  },
};

const COLUMN_SELECTOR_ID = 'players';
const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

const transKeys = {
  firstName: 'first name',
  lastName: 'last name',
  gender: 'gender',
  dateOfBirth: 'date of birth',
  nationality: 'nationality',
  age: 'age',
  notApplicable: 'n/a',
};

const getQueryVarsFromContextITA = (ctx) => {
  const { eq, between } = filterOperatorHelper;
  const { age, nationality, sex, search } = ctx.filters;
  const { limit, offset } = ctx.paging;

  return {
    filter: {
      group: { role: eq(GroupRole.PLAYER), type: eq(GroupType.ROSTER) },
      age: !isDefaultAgeRange(age) ? between(age) : undefined,
      nationalityCode: nationality ? eq(nationality) : undefined,
      sex: sex || undefined,
      search: search
        ? {
            fields: [CommonSearchOperatorsFieldsEnum.personId, CommonSearchOperatorsFieldsEnum.fullName],
            value: search,
          }
        : undefined,
    },
    limit,
    skip: offset,
    sort: ctx?.sorting?.length
      ? {
          field: ctx.sorting[0].property,
          direction: transformSortDirection(ctx.sorting[0].sortDirection),
        }
      : undefined,
  };
};

type OverridableProps = PropsFrom<typeof Players>;

type TableItemDefault = SearchPlayers_searchPeople_results & { id: string };
type TableItemITA = SearchPlayers_players_results & { id: string; currentSeasonId?: string };
type TableItemUSTA = SearchPeople_searchPeople_results & { id: string };

const clientProps = {
  ITA: {
    gqlQuery: queriesITA.SEARCH_PLAYERS,
    filtersConfig: () => {
      return {
        search: {
          ...useSearchFilter({
            placeholderTransKey: 'ita.players.filters.searchPlaceholder',
          }),
          position: 1,
          spacing: filterSpacing,
        },
        sex: {
          ...useGenderFilter(),
          position: 2,
          spacing: filterSpacing,
        },
        age: {
          ...useAgeFilter(),
          position: 3,
          spacing: filterSpacing,
        },
        nationality: {
          ...useNationalityFilter(),
          position: 4,
          spacing: filterSpacing,
        },
      };
    },
    tableConfig: ({ t }) => {
      const { getTranslation: getGenderTranslation } = usePersonGender();
      const { getCountryName } = useCountries('en');
      const { formatDate } = useDateFormat(DEFAULT_DATE_FORMAT);

      const getCsvTransforms = (seasonId: string): CSVTransform[] => [
        {
          key: 'id',
          label: 'personId',
        },
        {
          key: 'standardGivenName',
          label: t(transKeys.firstName),
        },
        {
          key: 'standardFamilyName',
          label: t(transKeys.lastName),
        },
        {
          key: 'sex',
          label: t(transKeys.gender),
          valueMap: [
            { in: SexEnum.MALE, out: t(personGenderTranslationMap[SexEnum.MALE]) },
            { in: SexEnum.FEMALE, out: t(personGenderTranslationMap[SexEnum.FEMALE]) },
          ],
        },
        {
          key: 'birthDate',
          label: t(transKeys.dateOfBirth),
          transforms: [
            {
              operation: CSVValueTransform.FORMAT_UTC_DATE,
              parameters: [{ key: 'dateFormat', value: DEFAULT_DATE_FORMAT }],
            },
          ],
        },
        {
          key: 'class',
          label: t('class'),
        },
        {
          key: 'nationalityCode',
          label: t(transKeys.nationality),
        },
        {
          key: 'groups',
          label: t('current team'),
          transforms: [
            {
              operation: CSVValueTransform.ARRAY_FILTER,
              parameters: [
                { key: 'fieldPath', value: 'seasonId' },
                { key: 'compareValue', value: seasonId },
              ],
            },
            {
              operation: CSVValueTransform.ARRAY_JOIN,
              parameters: [{ key: 'fieldPath', value: 'groupName' }],
            },
          ],
        },
        {
          key: 'email',
          label: t('email'),
        },
      ];

      return {
        columns: [
          {
            key: 'id',
            title: 'personId',
            getValue: (item: TableItemITA) => item.id || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.personId),
          },
          {
            key: 'standardGivenName',
            title: t(transKeys.firstName),
            getValue: (item: TableItemITA) => item.standardGivenName || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell, styles.stdName),
          },
          {
            key: 'standardFamilyName',
            title: t(transKeys.lastName),
            getValue: (item: TableItemITA) => item.standardFamilyName || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell, styles.stdName),
          },
          {
            key: 'sex',
            title: t(transKeys.gender),
            getValue: (item: TableItemITA) => getGenderTranslation(item.sex as SexEnum) || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
          },
          {
            key: 'birthDate',
            title: t(transKeys.dateOfBirth),
            getValue: (item: TableItemITA) => formatDate(item.birthDate) || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
          },
          {
            key: 'additionalFields.class',
            title: t('class'),
            getValue: (item: TableItemITA) => item.class || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell),
          },
          {
            key: 'nationalityCode',
            title: t(transKeys.nationality),
            getValue: (item: TableItemITA) => getCountryName(item.nationalityCode || '') || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
          },
          {
            key: 'groupName',
            title: t('current team'),
            getValue: (item: TableItemITA) => {
              if (!item.currentSeasonId) return t('loading...');

              const currentTeam = item.groups?.find((g) => g.seasonId === item.currentSeasonId);

              return currentTeam ? currentTeam.groupName : t(transKeys.notApplicable);
            },
            sort: false,
            widthClassName: cx(styles.tableCell),
          },
        ],
        accessoryBar: {
          items: [
            { type: 'pagination-info-top', position: 'left', props: {} },
            {
              type: 'btn-download-auto',
              position: 'right',
              props: (ctx) => {
                const currentSeasonId = ctx?.queryData?.currentSeason?.id;
                return {
                  generateButtonTitle: t('buttons.common.exportToCsv'),
                  paginator: { rootFieldPath: 'players.results' },
                  reportQuery: print(queriesITA.SEARCH_PLAYERS_DOWNLOAD),
                  reportQueryEndpoint: getEnvConfig().MESH_GATEWAY_GQL_URL,
                  reportQueryVariables: getQueryVarsFromContextITA(ctx),
                  filename: 'players',
                  csvTransforms: getCsvTransforms(currentSeasonId ?? ''),
                  csvFormatOptions: { disableUnwind: true },
                  buttonProps: {
                    level: 'tertiary',
                    disabled: !currentSeasonId,
                  },
                };
              },
            },
          ],
          gap: 12,
          spacing: accessoriesBarSpacing,
        },
        tableProps: {
          hideTopPaginationInfo: true,
          onRowClick: (data: TableItemITA) => navigate(`/players/${data?.id}`),
        },
      };
    },
    config: ({ t }) => {
      const { iso3ToIso2Map } = useCountries('en');
      return {
        columnSelectorId: COLUMN_SELECTOR_ID,
        urlParams: {
          search: {
            type: 'STRING',
          },
          sex: {
            type: 'STRING',
            paramName: 'gender',
            toUrlParam: (val) => (typeof val === 'string' ? val.toLowerCase() : ''),
            toValue: (urlParam) =>
              urlParam && personGenderTranslationMap[urlParam.toUpperCase()] ? urlParam.toUpperCase() : '',
          },
          age: {
            type: 'NUMERIC_ARRAY',
            toValue: (urlParam) => {
              if (
                !urlParam ||
                urlParam.length !== 2 ||
                urlParam.some((m) => !m || isNaN(m)) ||
                isRangeOutOfBounds(urlParam as [number, number], AGE_RANGE_DEFAULT)
              )
                return AGE_RANGE_DEFAULT;
              return urlParam as [number, number];
            },
          },
          nationality: {
            type: 'STRING',
            toValue: (urlParam) => (urlParam && iso3ToIso2Map[urlParam.toUpperCase()] ? urlParam.toUpperCase() : ''),
          },
        },
        mapFiltersToQueryOptions: ({ filters }) => {
          return {
            client: meshGatewayClient,
            getTotalItems: (data: SearchPlayersITA) => data?.players?.total,
            transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
              const { limit, offset, sorts } = vars;
              return getQueryVarsFromContextITA({
                filters,
                paging: { limit, offset },
                sorting: sorts,
              });
            },
            fetchPolicy: 'no-cache',
          };
        },
        mapDataToTable: (data: SearchPlayersITA) => {
          const tableItems = (data?.players?.results ?? []) as TableItemITA[];
          return tableItems.map((item) => {
            item['currentSeasonId'] = data?.currentSeason?.id;
            return item;
          });
        },
        depsFilterConfig: [t],
        depsTableConfig: [t],
      };
    },
  },
  USTA: {
    gqlQuery: queriesUSTA.SEARCH_PLAYERS,
    filtersConfig: ({ t }) => {
      const { sectionFilter, districtFilter } = useSectionAndDistrictFilters();

      return {
        search: {
          ...useSearchFilter({ placeholderTransKey: 'search name id' }),
          position: 1,
          spacing: filterSpacing,
        },
        category: {
          position: 2,
          type: 'select',
          initialValue: '',
          props: {
            options: [
              { label: t('any player category'), value: '' },
              { label: t('junior'), value: 'Junior' },
              { label: t('adult'), value: 'Adult' },
              { label: t('wheelchair'), value: 'Wheelchair' },
            ],
          },
          spacing: filterSpacing,
        },
        section: {
          ...sectionFilter,
          position: 3,
          spacing: filterSpacing,
        },
        district: {
          ...districtFilter,
          position: 4,
          spacing: filterSpacing,
        },
        sex: {
          ...useGenderFilter(),
          position: 5,
          spacing: filterSpacing,
        },
        age: {
          ...useAgeFilter(),
          position: 6,
          spacing: filterSpacing,
        },
      };
    },
    tableConfig: ({ t }) => {
      const OBSCURE_RESULTS_COUNT_THRESHOLD = 100;
      const obscureTotalConfig = { threshold: OBSCURE_RESULTS_COUNT_THRESHOLD };
      const storedColumnPreference = useMemo(() => retrieveColumnPreference(COLUMN_SELECTOR_ID), []);
      const { getTranslation: getGenderTranslation } = usePersonGender();
      return {
        columns: [
          {
            key: 'externalId',
            getValue: (item: TableItemUSTA) => item?.externalId || t(transKeys.notApplicable),
            title: t('usta id'),
            columnToggle: {
              checked: true,
              disabled: true,
            },
            sort: true,
          },
          {
            key: 'standardGivenName',
            getValue: (item: TableItemUSTA) => item?.standardGivenName || t(transKeys.notApplicable),
            title: t(transKeys.firstName),
            columnToggle: {
              checked: true,
              disabled: true,
            },
            sort: true,
          },
          {
            key: 'standardFamilyName',
            getValue: (item: TableItemUSTA) => item?.standardFamilyName || t(transKeys.notApplicable),
            title: t(transKeys.lastName),
            columnToggle: {
              checked: true,
              disabled: true,
            },
            sort: true,
          },
          {
            key: 'section',
            getValue: (item: TableItemUSTA) => item?.section?.name || t(transKeys.notApplicable),
            title: t('section'),
            columnToggle: {
              checked: storedColumnPreference?.section ?? true,
            },
            sort: true,
          },
          {
            key: 'district',
            getValue: (item: TableItemUSTA) => item?.district?.name || t(transKeys.notApplicable),
            title: t('district'),
            columnToggle: {
              checked: storedColumnPreference?.district ?? true,
            },
            sort: true,
          },
          {
            key: 'state',
            getValue: (item: TableItemUSTA) => item?.state || t(transKeys.notApplicable),
            title: t('state'),
            columnToggle: {
              checked: storedColumnPreference?.state ?? true,
            },
            sort: true,
          },
          {
            key: 'sex',
            getValue: (item: TableItemUSTA) => {
              const gender = item?.sex && getGenderTranslation(item?.sex ?? '');
              return gender || t(transKeys.notApplicable);
            },
            title: t(transKeys.gender),
            columnToggle: {
              checked: storedColumnPreference?.sex ?? true,
            },
            sort: true,
          },
          {
            key: 'age',
            getValue: (item: TableItemUSTA) => item?.age || t(transKeys.notApplicable),
            title: t(transKeys.age),
            columnToggle: {
              checked: storedColumnPreference?.age ?? true,
            },
            sort: true,
          },
        ],
        accessoryBar: {
          items: [
            {
              position: 'left',
              type: 'pagination-info-top',
              props: { obscureTotal: obscureTotalConfig },
            },
            { position: 'right', type: 'column-toggle', props: {} },
          ],
          gap: 12,
          spacing: accessoriesBarSpacing,
        },
        tableProps: {
          hideTopPaginationInfo: true,
          obscureTotalCount: obscureTotalConfig,
          onRowClick: (data: TableItemUSTA) => navigate(`/players/${data?.externalId}`),
        },
      };
    },
    config: ({ t }) => {
      return {
        columnSelectorId: COLUMN_SELECTOR_ID,
        urlParams: {
          search: {
            type: 'STRING',
          },
          category: {
            type: 'STRING',
          },
          section: {
            type: 'STRING',
          },
          district: {
            type: 'STRING',
          },
          sex: {
            type: 'STRING',
            paramName: 'gender',
            toUrlParam: (val) => (typeof val === 'string' ? val.toLowerCase() : ''),
            toValue: (urlParam) =>
              urlParam && personGenderTranslationMap[urlParam.toUpperCase()] ? urlParam.toUpperCase() : '',
          },
          age: {
            type: 'NUMERIC_ARRAY',
            toValue: (urlParam) => {
              if (
                !urlParam ||
                urlParam.length !== 2 ||
                urlParam.some((m) => !m || isNaN(m)) ||
                isRangeOutOfBounds(urlParam as [number, number], AGE_RANGE_DEFAULT)
              )
                return AGE_RANGE_DEFAULT;
              return urlParam as [number, number];
            },
          },
        },
        mapFiltersToQueryOptions: ({ filters, helper }) => {
          return {
            client: meshGatewayClient,
            getTotalItems: (data: SearchPlayersUSTA) => data?.searchPeople?.total,
            transformVariables: (v) => {
              const { sorts, limit, offset } = v;
              const { eq, between } = helper;
              const { search, category, section, district, sex, age } = filters;
              const filter = {
                search: typeof search === 'string' && search.length > 2 ? { value: search } : undefined,
                category: category || undefined,
                section: section ? eq(section) : undefined,
                district: district ? eq(district) : undefined,
                sex: sex || undefined,
                age: !isDefaultAgeRange(age as [number, number]) ? between(age as [number, number]) : undefined,
              };

              if (!sorts)
                return {
                  pageArgs: { limit, skip: offset },
                  filter,
                };

              const [{ sortDirection, property }] = sorts;
              return {
                pageArgs: { limit, skip: offset },
                filter,
                sort: { field: property, direction: transformSortDirection(sortDirection) },
              };
            },
            fetchPolicy: 'no-cache',
          };
        },
        mapDataToTable: (data: SearchPlayersUSTA) => data?.searchPeople?.results ?? [],
        depsFilterConfig: [t],
        depsTableConfig: [t],
      };
    },
  },
  DEFAULT: {
    gqlQuery: queriesDEFAULT.SEARCH_PLAYERS,
    filtersConfig: () => {
      return {
        search: {
          ...useSearchFilter({
            placeholderTransKey: 'playersModule.filters.searchPlaceholder.default',
          }),
          position: 1,
          spacing: filterSpacing,
        },
        sex: {
          ...useGenderFilter(),
          position: 2,
          spacing: filterSpacing,
        },
        age: {
          ...useAgeFilter(),
          position: 3,
          spacing: filterSpacing,
        },
        nationality: {
          ...useNationalityFilter(),
          position: 4,
          spacing: filterSpacing,
        },
      };
    },
    tableConfig: ({ t }) => {
      const { getTranslation: getGenderTranslation } = usePersonGender();
      const { getCountryName } = useCountries('en');
      const { formatDate } = useDateFormat(DEFAULT_DATE_FORMAT);
      const storedColumnPreference = useMemo(() => retrieveColumnPreference(COLUMN_SELECTOR_ID), []);

      return {
        columns: [
          {
            key: 'tennisId',
            title: t('tennis id'),
            getValue: (item: TableItemDefault) => item.tennisId || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.tennisId),
            columnToggle: {
              checked: storedColumnPreference?.tennisId ?? true,
            },
          },
          {
            key: 'standardGivenName',
            title: t(transKeys.firstName),
            getValue: (item: TableItemDefault) => item.standardGivenName || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell, styles.stdName),
            columnToggle: {
              checked: storedColumnPreference?.standardGivenName ?? true,
            },
          },
          {
            key: 'standardFamilyName',
            title: t(transKeys.lastName),
            getValue: (item: TableItemDefault) => item.standardFamilyName || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell, styles.stdName),
            columnToggle: {
              checked: storedColumnPreference?.standardFamilyName ?? true,
            },
          },
          {
            key: 'sex',
            title: t(transKeys.gender),
            getValue: (item: TableItemDefault) =>
              getGenderTranslation(item.sex as SexEnum) || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.gender),
            columnToggle: {
              checked: storedColumnPreference?.sex ?? true,
            },
          },
          {
            key: 'age',
            title: t(transKeys.age),
            getValue: (item: TableItemDefault) => item.age || t(transKeys.notApplicable),
            sort: true,
            widthClassName: cx(styles.tableCell, styles.age),
            columnToggle: {
              checked: storedColumnPreference?.age ?? true,
            },
          },
          {
            key: 'birthDate',
            title: t(transKeys.dateOfBirth),
            getValue: (item: TableItemDefault) => formatDate(item.birthDate) || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
            columnToggle: {
              checked: storedColumnPreference?.birthDate ?? false,
            },
          },
          {
            key: 'nationalityCode',
            title: t(transKeys.nationality),
            getValue: (item: TableItemDefault) =>
              getCountryName(item.nationalityCode || '') || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
            columnToggle: {
              checked: storedColumnPreference?.nationalityCode ?? true,
            },
          },
          {
            key: 'wtnSingles',
            title: t('singles WTN'),
            getValue: (item: TableItemDefault) =>
              getWtnValues(item?.worldTennisNumbers ?? []).ratingSingles ?? t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.wtnRating),
            columnToggle: {
              checked: storedColumnPreference?.wtnSingles ?? true,
            },
          },
          {
            key: 'confidenceSingles',
            title: t('singles confidence'),
            getValue: (item: TableItemDefault) =>
              getWtnValues(item?.worldTennisNumbers ?? []).confidenceSingles ?? t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.wtnRating),
            columnToggle: {
              checked: storedColumnPreference?.confidenceSingles ?? false,
            },
          },
          {
            key: 'wtnDoubles',
            title: t('doubles WTN'),
            getValue: (item: TableItemDefault) =>
              getWtnValues(item?.worldTennisNumbers ?? []).ratingDoubles ?? t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.wtnRating),
            columnToggle: {
              checked: storedColumnPreference?.wtnDoubles ?? true,
            },
          },
          {
            key: 'confidenceDoubles',
            title: t('doubles confidence'),
            getValue: (item: TableItemDefault) =>
              getWtnValues(item?.worldTennisNumbers ?? []).confidenceDoubles ?? t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.wtnRating),
            columnToggle: {
              checked: storedColumnPreference?.confidenceDoubles ?? false,
            },
          },
          {
            key: 'id',
            title: t('person id'),
            getValue: (item: TableItemDefault) => item.id || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell, styles.personId),
            columnToggle: {
              checked: storedColumnPreference?.id ?? false,
            },
          },
          {
            key: 'updatedAt',
            title: t('last updated'),
            getValue: (item: TableItemDefault) => formatDate(item.updatedAt) || t(transKeys.notApplicable),
            sort: false,
            widthClassName: cx(styles.tableCell),
            columnToggle: {
              checked: storedColumnPreference?.updatedAt ?? false,
            },
          },
        ],
        accessoryBar: {
          items: [
            { type: 'pagination-info-top', position: 'left', props: {} },
            { position: 'right', type: 'column-toggle', props: {} },
          ],
          gap: 12,
          spacing: accessoriesBarSpacing,
        },
        tableProps: {
          hideTopPaginationInfo: true,
        },
      };
    },
    config: ({ t }) => {
      const { iso3ToIso2Map } = useCountries('en');
      return {
        columnSelectorId: COLUMN_SELECTOR_ID,
        urlParams: {
          search: {
            type: 'STRING',
          },
          sex: {
            type: 'STRING',
            paramName: 'gender',
            toUrlParam: (val) => (typeof val === 'string' ? val.toLowerCase() : ''),
            toValue: (urlParam) =>
              urlParam && personGenderTranslationMap[urlParam.toUpperCase()] ? urlParam.toUpperCase() : '',
          },
          age: {
            type: 'NUMERIC_ARRAY',
            toValue: (urlParam) => {
              if (
                !urlParam ||
                urlParam.length !== 2 ||
                urlParam.some((m) => !m || isNaN(m)) ||
                isRangeOutOfBounds(urlParam as [number, number], AGE_RANGE_DEFAULT)
              )
                return AGE_RANGE_DEFAULT;
              return urlParam as [number, number];
            },
          },
          nationality: {
            type: 'STRING',
            toValue: (urlParam) => (urlParam && iso3ToIso2Map[urlParam.toUpperCase()] ? urlParam.toUpperCase() : ''),
          },
        },
        mapFiltersToQueryOptions: ({ filters, helper: { eq, between } }) => {
          return {
            client: meshGatewayClient,
            getTotalItems: (data: SearchPlayers) => data?.searchPeople?.total,
            transformVariables: (vars: { limit?: number; offset?: number; sorts?: PropertySortInput[] }) => {
              const { limit, offset, sorts: sorting } = vars;
              const { age, nationality, sex, search } = filters;

              return {
                filter: {
                  age: !isDefaultAgeRange(age as [number, number]) ? between(age as [number, number]) : undefined,
                  nationalityCode: nationality ? eq(nationality) : undefined,
                  sex: sex || undefined,
                  search: search
                    ? {
                        fields: [
                          CommonSearchOperatorsFieldsEnum.tennisId,
                          CommonSearchOperatorsFieldsEnum.personId,
                          CommonSearchOperatorsFieldsEnum.fullName,
                        ],
                        value: search,
                      }
                    : undefined,
                },
                limit,
                skip: offset,
                sort: sorting?.length
                  ? {
                      field: sorting[0].property,
                      direction: transformSortDirection(sorting[0].sortDirection),
                    }
                  : undefined,
              };
            },
            fetchPolicy: 'no-cache',
          };
        },
        mapDataToTable: (data: SearchPlayers) => data?.searchPeople?.results ?? [],
        depsFilterConfig: [t],
        depsTableConfig: [t],
      };
    },
  },
} as unknown as ClientPropsConfig<OverridableProps>;

export type ConfigOptions = keyof typeof clientProps;

export const getClientProps = () => {
  const { playersModule } = getClientConfig();
  const setting = playersModule?.playersPage ?? '';
  return (clientProps[setting] ?? clientProps.DEFAULT) as OverridableProps;
};
